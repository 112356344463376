import axios from "../http";
import qs from "qs";

export function addTemplate(data) {
  return axios.post("/template/add", data);
}

export function updateTemplate(data) {
  return axios.post("/template/update", data);
}

export function delTemplate(data) {
  return axios.post("/template/del", qs.stringify(data));
}

export function listTemplate(data) {
  return axios.post("/template/list", qs.stringify(data));
}

export function resetTemplate(data) {
  return axios.post("/template/reset", data);
}

export function getTemplateName(data) {
  return axios.post("/template/getTemplateName", qs.stringify(data));
}

export function updateTemplateName(data) {
  return axios.post("/template/updateTemplateName", qs.stringify(data));
}

export function getTemplateDetail(params) {
  return axios.get("/template/get", {
    params,
  });
}

export function getMemberDetail(params) {
  return axios.get("/template/getMember", {
    params,
  });
}
